import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useContext, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { BaseUrlContext, ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import { useMetricsStore } from '@core/store';
import { stringifyOptions } from '@core/store/Metrics/constants';
import { smartCaps, toKeyString } from '@core/utils/metrics';
import shortUrl from '@core/utils/shortUrl';

import Badge from '@ui/Badge';
import Box from '@ui/Box';
import MetricsGraph from '@ui/Metrics/Graph';
import { graphTypeToUnits, groupByFilterToColumn } from '@ui/Metrics/Graph/utils';
import Spinner from '@ui/Spinner';

import useFallback from '../Fallbacks/useFallback';
import { useGetActiveGroup } from '../Filters/Group';
import GraphTotals from '../GraphTotals';
import MetricsBanner from '../MetricsBanner';
import MetricsPageTable from '../Table';

import Shortcuts from './Shortcuts';
import styles from './style.module.scss';

function rangeLengthToRange(rangeLength, resolution) {
  if (resolution === 'week' && rangeLength === 12) return 'This Quarter';
  if (resolution === 'month' && rangeLength === 12) return 'This Year';
  if (resolution === 'day' && rangeLength === 30) return 'This Month';
  if (resolution === 'day' && rangeLength === 7) return 'This Week';
  if (resolution === 'hour' && rangeLength === 24) return 'Last 24 Hours';
  return '';
}

const MetricsPage = ({ isSuperhub }) => {
  const bem = useClassy(styles, 'MetricsPage');

  const baseUrl = useContext(BaseUrlContext);
  const { slug = '' } = useParams();
  const location = useLocation();
  const { project } = useContext(ProjectContext);
  const [
    updateMetricsPageConfig,
    updateQuery,
    developmentData,
    showDemoData,
    isUsageReady,
    customerUsage,
    metricsPageConfig,
    query,
    graphQuery,
    tableQuery,
    selectedDateRangeKey,
  ] = useMetricsStore(s => [
    s.updateMetricsPageConfig,
    s.updateQuery,
    s.developmentData,
    s.showDemoData,
    s.isUsageReady,
    s.customerUsage,
    s.metricsPageConfig,
    s.query,
    s.graphQuery,
    s.tableQuery,
    s.selectedDateRangeKey,
  ]);

  const isPageQuality = slug === 'page-quality';
  const isEndpoints = slug === 'top-endpoints';
  const isErrors = slug === 'api-errors';

  const rangeLength = query.rangeLength;
  const resolution = query.resolution;

  const activeGroup = useGetActiveGroup();
  const routeKey = slug ? toKeyString(slug) : slug;

  const { shouldRenderFallback, fallback } = useFallback(metricsPageConfig?.fallback);

  const fetchGraphQuery = useMemo(() => {
    const nextQuery = {
      ...graphQuery,
      ...query,
    };

    // Don't send certain query params for non /requests endpoints (Doc Metrics)
    if (metricsPageConfig.endpoint !== 'requests') {
      delete nextQuery.development;
      delete nextQuery.tryItNow;
      delete nextQuery.demo;
    }

    return nextQuery;
  }, [graphQuery, query, metricsPageConfig.endpoint]);

  const theme = useMemo(() => {
    const isActiveGroupStatus = ['status', 'statusCategory'].includes(activeGroup);
    return isActiveGroupStatus ? 'status-codes' : metricsPageConfig.graph.theme;
  }, [activeGroup, metricsPageConfig.graph.theme]);

  const isReadyToFetchData = useMemo(() => {
    // Prevent fetches until:
    // - usage data is ready
    // - slug matches the current route OR for /user page, the email query param is set
    // - groupBy is present in the query
    return (
      (metricsPageConfig.endpoint !== 'requests' || isUsageReady) &&
      (slug === kebabCase(metricsPageConfig.title) ||
        (metricsPageConfig.type === 'users' && !!fetchGraphQuery.email)) &&
      !!fetchGraphQuery.groupBy
    );
  }, [isUsageReady, metricsPageConfig, slug, fetchGraphQuery]);

  useEffect(() => {
    // On route change, clear all filter params from base query params
    if (routeKey !== toKeyString(slug)) {
      const nextQuery = {
        ...query,
        compare: null,
        vote: null,
        method: null,
        status: null,
        path: null,
      };

      updateQuery('query', nextQuery);
    }
  }, [routeKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const isComparison = fetchGraphQuery.compare === 'true';

  /**
   * Update Graph from /historical
   * For the /requests powered data, wait for the usage data and query params to be ready
   */
  const graph = useMetricsAPI(
    `${metricsPageConfig.endpoint}/historical?${qs.stringify(fetchGraphQuery, stringifyOptions)}`,
    isReadyToFetchData,
  );

  const pageQualityTotal = useMemo(() => {
    if (!isPageQuality || !graph?.data?.dataSet) return { current: 0, previous: 0 };

    const dataSet = graph?.data?.dataSet;
    const comparisonSet = graph?.data?.comparisonSet;

    const current = Object.values(dataSet?.data || {}).reduce((acc, val) => {
      if (Array.isArray(val)) {
        const subVal = val.reduce((a, v) => a + Math.abs(v), 0);
        return acc + subVal;
      }
      return acc;
    }, 0);

    const previous = Object.values(comparisonSet?.data || {}).reduce((acc, val) => {
      if (Array.isArray(val)) {
        const subVal = val.reduce((a, v) => a + Math.abs(v), 0);
        return acc + subVal;
      }
      return acc;
    }, 0);
    return { current, previous };
  }, [isPageQuality, graph?.data?.dataSet, graph?.data?.comparisonSet]);

  const graphRef = useRef();
  const [graphHeight, setGraphHeight] = useState(500);
  const graphStyles = useMemo(
    () => ({ height: graph.isLoading || (!!shouldRenderFallback && isPageQuality) ? graphHeight : undefined }),
    [graph.isLoading, graphHeight, isPageQuality, shouldRenderFallback],
  );

  /** Determine scale used in the primary axis of the dataset (aka graph.data.labels)
   *  in order to override the graph default of an interval scale when a groupBy=period query param is not present
   *  (for the grouped page quality data scenario)
   */
  const graphPrimaryScale = useMemo(() => {
    return fetchGraphQuery.groupBy && !fetchGraphQuery.groupBy.includes('period') ? 'ordinal' : 'interval';
  }, [fetchGraphQuery.groupBy]);

  useEffect(() => {
    const waitToSetRefHeight = setTimeout(() => {
      const h = graphRef.current.getBoundingClientRect()?.height;
      setGraphHeight(h);
    }, 0);
    return () => clearTimeout(waitToSetRefHeight);
  }, [graph.isLoading, metricsPageConfig?.graph?.query]);

  const handleShortcutClick = useCallback(
    shortcut => {
      const {
        graphType,
        query: shortcutQuery,
        rangeLength: shortcutRangeLength,
        resolution: shortcutResolution,
        ...params
      } = metricsPageConfig?.shortcuts?.[shortcut] || {};

      if (shortcutRangeLength && shortcutResolution) {
        updateQuery('query', { rangeLength: shortcutRangeLength, resolution: shortcutResolution });
      }

      updateMetricsPageConfig({
        ...metricsPageConfig,
        graph: {
          ...metricsPageConfig.graph,
          type: graphType || metricsPageConfig.graph.type,
        },
      });

      // Update graph and table queries
      updateQuery('graphQuery', { ...qs.parse(shortcutQuery), ...params });
      updateQuery('tableQuery', {});
    },
    [metricsPageConfig, updateQuery, updateMetricsPageConfig],
  );

  const handleUpdatingGraphGroupFilter = useCallback(
    (groupBy, groupFilters, isAllSelected) => {
      const nextQuery = qs.parse(tableQuery);

      // Send any empty strings or nulls as 'null' to the API for filtering on empty values
      const parsedFilters = groupFilters.map(filter => (filter === '' || filter === null ? 'null' : filter));

      // If all are selected, don't add any query params (this will just reset query back to default)
      if (!isAllSelected) {
        nextQuery[groupByFilterToColumn(groupBy)] = parsedFilters;
      }

      updateQuery('tableQuery', {
        ...nextQuery,
        // Reset the page when changing group filters
        page: null,
      });
    },
    [tableQuery, updateQuery],
  );

  // On mount, check for a hash in the URL and trigger a shortcut click
  useEffect(() => {
    if (location.hash) {
      const hash = smartCaps(location.hash.slice(1));
      handleShortcutClick(hash);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const data = useMemo(() => {
    const { dataSet = {}, comparisonSet = {}, labels = [] } = graph.data || {};
    if (Object.keys(dataSet).length === 0) {
      return { datasets: [{ data: [] }] };
    }
    const datasets = isComparison ? [dataSet, comparisonSet] : [dataSet];
    return { labels, datasets };
  }, [graph.data, isComparison]);

  const isDevData = metricsPageConfig.endpoint === 'requests' && developmentData;
  const isMissingData = metricsPageConfig.endpoint === 'requests' && isUsageReady && !customerUsage?.sdk.thirtyDay;

  // Most badges are red when the change decreases, but in certain cases it should be green as the change is "positive" (in the subjective rather than the numerical sense)
  const isPositiveChange = isErrors ? graph.data?.changed < 0 : graph.data?.changed >= 0;
  const badgeType = isPositiveChange ? 'success' : 'failure';

  return (
    <>
      <Box
        ref={graphRef}
        className={bem('-graphWrap', graph.isLoading && '-graphWrap_loading')}
        kind="card"
        style={graphStyles}
        theme={isSuperhub ? 'dark-on-black' : undefined}
      >
        {!shouldRenderFallback && graph.isLoading ? (
          <Spinner size="lg" />
        ) : (
          <MetricsGraph
            aspectRatio={3.5}
            baseUrl={baseUrl}
            className={bem('-graph')}
            currentDateRange={selectedDateRangeKey}
            data={data}
            fallback={fallback}
            footerContent={
              metricsPageConfig?.shortcuts && Object.keys(metricsPageConfig.shortcuts).length ? (
                <Shortcuts onShortcutClick={handleShortcutClick} />
              ) : undefined
            }
            groupFilterName={smartCaps(activeGroup)}
            heading={
              <>
                {metricsPageConfig.title}{' '}
                {!!shouldRenderFallback && !isPageQuality && (
                  <Badge allCaps kind="standard">
                    Setup
                  </Badge>
                )}
                {graph.data?.changed != null && !shouldRenderFallback && (
                  <Badge className={bem('-delta')} kind={badgeType}>
                    <GraphTotals
                      changed={graph.data?.changed}
                      isColorCoded={false}
                      isNetSum={isPageQuality}
                      range={rangeLengthToRange(rangeLength, resolution)}
                      units={graphTypeToUnits(metricsPageConfig.title)}
                    />
                  </Badge>
                )}
              </>
            }
            isCompare={isComparison}
            isDemoData={!!showDemoData}
            isDevData={isDevData}
            isDisabled={!!shouldRenderFallback && !isPageQuality}
            isMissingData={isMissingData}
            isStacked={metricsPageConfig.graph.stacked}
            onGroupFilterChange={handleUpdatingGraphGroupFilter}
            primaryAxisScale={graphPrimaryScale}
            shouldRenderFallback={shouldRenderFallback}
            subdomain={project.subdomain}
            subheading={
              isEndpoints && graph.data?.topEndpoint ? (
                <>
                  <span>
                    {isComparison ? '' : '#1'}{' '}
                    <span className={bem('-subheading-url')}>{shortUrl(graph.data.topEndpoint, false)}</span>
                  </span>
                  {!!isComparison && !!graph.data?.prevTopEndpoint && (
                    <>
                      <Badge className={bem('-vs')} kind="dark">
                        VS
                      </Badge>
                      <span className={bem('-delta_comparison', '-subheading-url')}>
                        {shortUrl(graph.data.prevTopEndpoint, false)}
                      </span>
                    </>
                  )}
                </>
              ) : (
                <>
                  <GraphTotals
                    isNetSum={isPageQuality}
                    pageQualityTotal={pageQualityTotal.current}
                    range={rangeLengthToRange(rangeLength, resolution)}
                    total={graph.data?.total}
                    units={graphTypeToUnits(metricsPageConfig.title)}
                  />
                  {!!isComparison && (
                    <>
                      <Badge className={bem('-vs')} kind="dark">
                        VS
                      </Badge>
                      <span className={bem('-delta_comparison')}>
                        <GraphTotals
                          isNetSum={isPageQuality}
                          pageQualityTotal={pageQualityTotal.previous}
                          range={rangeLengthToRange(rangeLength, resolution)}
                          total={graph.data?.prevTotal}
                          units={graphTypeToUnits(metricsPageConfig.title)}
                        />
                      </span>
                    </>
                  )}
                </>
              )
            }
            theme={theme}
            title={metricsPageConfig.title}
            type={metricsPageConfig.graph.type || 'line'}
            useDarkModeTooltips={isSuperhub}
            version={project.stable.version}
          />
        )}
      </Box>

      {['api-calls', 'top-endpoints', 'api-errors'].includes(slug) && <MetricsBanner />}

      {!shouldRenderFallback && (
        <MetricsPageTable key={routeKey} isReadyToFetchData={isReadyToFetchData} isSuperhub={isSuperhub} />
      )}
    </>
  );
};

MetricsPage.propTypes = {
  /** Whether page is being used in Superhub (so graph and table elements can have dark-on-black styling) */
  isSuperhub: PropTypes.bool,
};

export default MetricsPage;
