import type { CustomPageReadCollectionType } from '@readme/api/src/mappings/custompage/types';
import type { Path } from 'react-hook-form';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useReadmeApi from '@core/hooks/useReadmeApi';
import { useSuperHubStore } from '@core/store';

import type { ProjectSettingsFormValues } from '@routes/SuperHub/Settings/Form/Project/Context';

import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuItem, MenuDivider } from '@ui/Menu';
import Spinner from '@ui/Spinner';

import AddPageInput from './AddPageInput';
import classes from './index.module.scss';

interface CustomPagePickerProps {
  /**
   * Unique id for the CustomPagePicker.
   */
  id: string | undefined;
  /**
   * Name of the field for the AddPageInput to register any API errors with.
   */
  name: string;
  /**
   * Callback called when the user selects a page.
   */
  onChange: (value: string) => void;
  /**
   * Value of the selected page.
   */
  value: string | undefined;
}

function CustomPagePicker({ id, name, onChange, value }: CustomPagePickerProps) {
  const bem = useClassy(classes, 'CustomPagePicker');
  const [displayMode, setDisplayMode] = React.useState<'addPage' | 'select'>('select');
  const defaultApiOptions = useSuperHubStore(s => s.defaultApiOptions);

  const apiBaseUrl = useSuperHubStore(s => s.apiBaseUrl);
  const { data, isLoading, mutate } = useReadmeApi<CustomPageReadCollectionType>(
    `${apiBaseUrl}/custom_pages?page=1&per_page=100`,
    {
      ...defaultApiOptions,
      swr: {
        revalidateOnFocus: true,
        shouldRetryOnError: true,
      },
    },
  );

  const pages = data?.data || [];

  if (isLoading) {
    return (
      <Flex align="center" justify="center">
        <Spinner size="sm" />
      </Flex>
    );
  }

  if (displayMode === 'addPage') {
    return (
      <AddPageInput
        name={name as Path<ProjectSettingsFormValues>}
        onExit={page => {
          if (page) {
            mutate({
              data: pages ? [...pages, page.data] : [page.data],
            } as CustomPageReadCollectionType);
            onChange(page.data.slug || '');
          }
          setDisplayMode('select');
        }}
      />
    );
  }

  const hasPlaceholder = !value?.length;

  return (
    <Dropdown appendTo={() => document.body} className={bem('&')} clickInToClose fullWidth id={id} sticky>
      <button className={bem('-toggle', hasPlaceholder && '-toggle_placeholder')} type="button">
        {hasPlaceholder ? 'Select a page' : pages?.find(opt => opt.slug === value)?.title}
        <Icon name="chevron-down" />
      </button>
      <Menu className={bem('-menu')}>
        {pages?.length ? (
          pages.map(({ title, slug: optionValue }) => (
            <MenuItem
              key={optionValue}
              active={value === optionValue}
              onClick={() => {
                if (value !== optionValue) onChange(optionValue);
              }}
            >
              {title}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No custom pages found</MenuItem>
        )}
        <MenuDivider />
        <MenuItem color="blue" icon={<Icon name="plus" strokeWeight={3} />} onClick={() => setDisplayMode('addPage')}>
          New Custom Page
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}

export default CustomPagePicker;
