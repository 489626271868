// List of Amazon Web Services (AWS) non-government regions as of 2024-12-01
export enum AWS_REGIONS {
  BAHRAIN = 'me-south-1',
  BEIJING = 'cn-north-1',
  CALGARY = 'ca-west-1',
  CALIFORNIA = 'us-west-1',
  CANADA = 'ca-central-1',
  CAPETOWN = 'af-south-1',
  FRANKFURT = 'eu-central-1',
  HONGKONG = 'ap-east-1',
  HYDERABAD = 'ap-south-2',
  IRELAND = 'eu-west-1',
  JAKARTA = 'ap-southeast-3',
  LONDON = 'eu-west-2',
  MALAYSIA = 'ap-southeast-5',
  MELBOURNE = 'ap-southeast-4',
  MILAN = 'eu-south-1',
  MUMBAI = 'ap-south-1',
  NINGXIA = 'cn-northwest-1',
  OHIO = 'us-east-2',
  OREGON = 'us-west-2',
  OSAKA = 'ap-northeast-3',
  PARIS = 'eu-west-3',
  SAOPAULO = 'sa-east-1',
  SEOUL = 'ap-northeast-2',
  SINGAPORE = 'ap-southeast-1',
  SPAIN = 'eu-south-2',
  STOCKHOLM = 'eu-north-1',
  SYDNEY = 'ap-southeast-2',
  TELAVIV = 'il-central-1',
  TOKYO = 'ap-northeast-1',
  UAE = 'me-central-1',
  VIRGINIA = 'us-east-1',
  ZURICH = 'eu-central-2',
}
