import { AWS_REGIONS } from '@readme/api/src/mappings/aws/types';
import React, { useContext, useEffect, useMemo, useRef, useState, useCallback } from 'react';

import type { ConfigContextValue, ProjectContextValue } from '@core/context';
import { APIBaseUrlContext, ConfigContext } from '@core/context';
import useAmplitude, { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY } from '@core/hooks/useAmplitude';
import useClassy from '@core/hooks/useClassy';
import { fetcher } from '@core/hooks/useReadmeApi';
import useSaveProject from '@core/hooks/useSaveProject/index';
import { useProjectStore } from '@core/store';
import { getDecoratedHeaders } from '@core/utils/makeFetch';

import ErrorModal from '@routes/MyDevelopers/Setup/PersonalizedDocs/ErrorModal';
import FormError, {
  FormErrorDescription,
  FormErrorHeading,
} from '@routes/MyDevelopers/Setup/PersonalizedDocs/FormError';

import Button from '@ui/Button';
import CodeSnippet from '@ui/CodeSnippet';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import type Modal from '@ui/Modal';
import Title from '@ui/Title';

import PanelContent from '../PanelContent';
import useSaveWebhook from '../useSaveWebhook';

import { awsApiGatewayPolicyText } from './AWSConstants';
import AWSCrossAccountInfo from './AWSCrossAccountInfo';
import styles from './style.module.scss';

interface SetupAWSPersonalizationFormProps {
  onSaveWebhook?: (url: string) => void;
}

type SetupError = Error & {
  info: {
    message: string;
    type: 'access-denied' | 'invalid-usage-plan' | 'missing-params';
  };
  status: number;
};

function SetupAWSPersonalizationForm({ onSaveWebhook }: SetupAWSPersonalizationFormProps) {
  const bem = useClassy(styles, 'SetupAWSPersonalizationForm');
  const modalRef = useRef<Modal>(null);

  const { data, saveProject: saveProjectV1 } = useSaveProject();
  const project = data as ProjectContextValue['project'];
  const saveProjectV2 = useProjectStore(store => store.save);
  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  const awsLoginIntegration = useMemo(() => {
    if (project.integrations?.login?.kind === 'aws') {
      return project.integrations?.login;
    }
    return null;
  }, [project.integrations?.login]);

  const { track } = useAmplitude();
  const [roleArn, setRoleArn] = useState(() => awsLoginIntegration?.roleArn || '');
  const [region, setRegion] = useState(() => (awsLoginIntegration?.region as AWS_REGIONS) || undefined);
  const [usagePlanId, setUsagePlanId] = useState(() => awsLoginIntegration?.usagePlanId || '');

  // the initialWebhookURL arg isn't needed since we're not tracking the state of the webhook
  // url via a user controlled input, but construct it ourselves on save
  const { handleSave: saveWebhook, handleUpdate: updateWebhook } = useSaveWebhook('', onSaveWebhook);
  const { subdomain, _id: externalId } = project;
  const [setupCompleted, setSetupCompleted] = useState(false);
  const [setupError, setSetupError] = useState<SetupError>();
  const [isLoading, setIsLoading] = useState(false);
  const apiBaseUrl = useContext(APIBaseUrlContext);
  const host = apiBaseUrl !== '/' ? apiBaseUrl : '';

  useEffect(() => {
    if (project?.fullBaseUrl) {
      updateWebhook(`${project.fullBaseUrl}${subdomain}/api-next/v2/webhooks/aws-login`);
    }
  }, [project.fullBaseUrl, subdomain, updateWebhook]);

  const testWebhook = useCallback(
    async formData => {
      setIsLoading(true);
      setSetupCompleted(false);
      setSetupError(undefined);

      try {
        if (!project?.fullBaseUrl) {
          throw new Error('Something went wrong. Please refresh the page and try again.');
        }

        await fetcher(`${host}/${subdomain}/api-next/v2/webhooks/aws-validate`, {
          headers: getDecoratedHeaders(),
          method: 'post',
          body: JSON.stringify(formData),
        });

        if (isHub) {
          await saveProjectV2({
            integrations: {
              aws: {
                readme_webhook_login: {
                  role_arn: roleArn,
                  region,
                  usage_plan_id: usagePlanId,
                  external_id: externalId,
                },
              },
            },
          });
        } else {
          await saveProjectV1({
            integrations: {
              ...project.integrations,
              login: { kind: 'aws', roleArn, region, usagePlanId, externalId },
            },
          });
        }

        await saveWebhook();
        setSetupCompleted(true);
      } catch (err) {
        setSetupError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [
      externalId,
      host,
      isHub,
      project?.fullBaseUrl,
      project.integrations,
      region,
      roleArn,
      saveProjectV1,
      saveProjectV2,
      saveWebhook,
      subdomain,
      usagePlanId,
    ],
  );

  function toggleModal() {
    if (modalRef.current) modalRef.current.toggle();
  }

  const isValidArn = !!roleArn;
  const isValidRegion = region && Object.values(AWS_REGIONS).includes(region);
  const isValidUsageId = usagePlanId?.length >= 6;
  const validOptions = isValidUsageId && isValidArn && isValidRegion;

  const { errorMessage, errorModalHeader, errorModalText } = useMemo(() => {
    if (!setupError) return { errorMessage: null, errorModalHeader: null, errorModalText: null };

    const defaultErrorMessage = (
      <FormError>
        <FormErrorHeading>There was a problem setting up your resources.</FormErrorHeading>
      </FormError>
    );
    if (setupError?.status >= 400)
      switch (setupError?.info?.type) {
        case 'missing-params':
          return {
            errorMessage: (
              <FormError>
                <FormErrorHeading>Missing Params</FormErrorHeading>
                <FormErrorDescription>
                  Did you forget to fill in the AWS Access Key ID, AWS Secret Access Key ID, or AWS Usage Plan ID?
                </FormErrorDescription>
              </FormError>
            ),
            errorModalHeader: null,
            errorModalText: null,
          };
        case 'invalid-usage-plan':
          return {
            errorMessage: (
              <FormError>
                <FormErrorHeading>Usage Plan Not Found</FormErrorHeading>
                <FormErrorDescription>{setupError?.info?.message}</FormErrorDescription>
              </FormError>
            ),
            errorModalHeader: null,
            errorModalText: null,
          };
        case 'access-denied':
          return {
            errorMessage: (
              <Button kind="destructive" onClick={toggleModal} size="xs" text>
                Provided credentials do not correct permissions.
                <Icon name="maximize-2" title="Show details" />
              </Button>
            ),
            errorModalHeader: 'Provided credentials do not have correct permissions.',
            errorModalText: `The credentials you provided do not have the correct permissions to create the resources needed for the webhook. Please make sure you have the correct permissions and try again. error: ${setupError.info?.message}`,
          };
        default:
          break;
      }

    return { errorMessage: defaultErrorMessage, errorModalHeader: null, errorModalText: null };
  }, [setupError]);

  return (
    <form
      onSubmit={ev => {
        ev.preventDefault();
        // Send event to amplitude
        if (validOptions) {
          track(AMPLITUDE_EVENT.PD_WEBHOOK_TEST, {
            type: AMPLITUDE_EVENT_PROPERTY.SETUP,
          });
        }

        testWebhook({
          externalId,
          roleArn,
          region,
          usagePlanId,
        });
      }}
    >
      <PanelContent>
        <Title as={3} level={5}>
          Step 1: Create IAM Role
        </Title>
        <AWSCrossAccountInfo externalId={externalId} />

        <Title as={3} level={5}>
          Step 2: Give us required information
        </Title>
        <label className={bem('-label')}>
          <div className={bem('-label-title')}>Role ARN</div>
          <p className={bem('-label-subtitle')}>
            Provided by AWS once the IAM role is created. It allows ReadMe to assume the IAM role.
          </p>
          <Input
            className={bem('-input')}
            id="roleArn"
            name="roleArn"
            onInput={ev => {
              const target = ev.target as HTMLInputElement;
              setRoleArn(target.value);
            }}
            placeholder="arn:aws:iam::012345678901:role/ReadMeSyncRole"
            required
            size="md"
            value={roleArn}
          />
        </label>

        <label className={bem('-label')}>
          <div className={bem('-label-title')}>AWS Region</div>
          <p className={bem('-label-subtitle')}>The region your usage plan lives in.</p>
          <Input
            className={bem('-input')}
            id="region"
            name="region"
            onInput={ev => {
              const target = ev.target as HTMLInputElement;
              setRegion(target.value as AWS_REGIONS);
            }}
            placeholder="us-east-1"
            required
            size="md"
            type=""
            value={region}
          />
        </label>

        <label className={bem('-label')}>
          <div className={bem('-label-title')}>AWS Usage Plan ID</div>
          <p className={bem('-label-subtitle')}>The Usage plan to create api keys under.</p>
          <Input
            className={bem('-input')}
            id="usagePlanId"
            minLength={5}
            name="usagePlanId"
            onInput={ev => {
              const target = ev.target as HTMLInputElement;
              setUsagePlanId(target.value);
            }}
            placeholder="1a2bc3"
            required
            size="md"
            value={usagePlanId}
          />
        </label>

        <Button
          className={bem('-submit')}
          disabled={!validOptions}
          fullWidth
          loading={isLoading}
          size="md"
          type="submit"
        >
          Setup
        </Button>

        {!!setupCompleted && (
          <Flex align="center" gap="xs" justify="center">
            <Icon name="check-circle" />
            <span>Success! We are linked to your AWS API Gateway</span>
          </Flex>
        )}
        {!!errorMessage && (
          <>
            <Flex justify="center">{errorMessage}</Flex>
            {!!errorModalHeader && !!errorModalText && (
              <ErrorModal
                ref={modalRef}
                errorHeader={errorModalHeader}
                errorText={errorModalText}
                target="#modal-target"
                toggle={toggleModal}
                webhookCodeSnippet={<CodeSnippet code={awsApiGatewayPolicyText} language={'json'}></CodeSnippet>}
              />
            )}
          </>
        )}
      </PanelContent>
    </form>
  );
}

export default SetupAWSPersonalizationForm;
