// This policy should give permissions to CRUD API keys and usage plans.
export const awsApiGatewayPolicyText = `{
  "Version": "2012-10-17",
  "Statement": [
      {
          "Sid": "Stmt909923626743",
          "Effect": "Allow",
          "Action": [
              "apigateway:GET",
              "apigateway:PUT",
              "apigateway:POST",
              "apigateway:DELETE"
          ],
          "Resource": [
              "arn:aws:apigateway:*::/apikeys",
              "arn:aws:apigateway:*::/apikeys/*",
              "arn:aws:apigateway:*::/usageplans/*/keys",
              "arn:aws:apigateway:*::/tags/*"
          ]
      }
  ]
}`;
