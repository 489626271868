import base64url from 'base64url';
import { useCallback, useContext } from 'react';

import { dashNavigate } from '@Dash/history';

import { BaseUrlContext, ConfigContext, ProjectContext } from '@core/context';
import type { ConfigContextValue, ProjectContextValue } from '@core/context';
import { MyDevelopersSubrouteType } from '@core/enums/metrics';
import { useMetricsStore } from '@core/store';

interface BuildUrlOptions {
  /** The identifier in URL (i.e. the type's slug) */
  identifier?: string;
  /** Optional request ID to append to the URL, used in MetricsSearch result links */
  requestId?: string;
  /** The subroute type */
  type?: MyDevelopersSubrouteType.Key | MyDevelopersSubrouteType.Segment | MyDevelopersSubrouteType.User;
}

/**
 * Helper hook to help with navigation of MyDevelopers subroutes
 * by obfuscating away Hub vs. Dash differences
 */
export default function useMyDevelopers() {
  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const baseUrl = useContext(BaseUrlContext);
  const isSetupComplete = useMetricsStore(s => s.isSetupComplete);

  const isSuperHub = project.flags.superHub;

  const isHub = name === 'Hub';
  const isDash = name === 'Dash';

  const isHubGoLink = isDash && isSuperHub;

  /**
   * Handles navigation based on App type (Dash or Hub)
   */
  const navigate = useCallback(
    url => {
      if (isHubGoLink) {
        window.open(url, '_blank', 'noreferrer');
        return;
      }

      if (isDash) {
        dashNavigate(url);
        return;
      }

      if (isHub) {
        window.location.hash = url;
      }
    },
    [isDash, isHub, isHubGoLink],
  );

  /**
   * Builds URL based on the current App (Dash or Hub) and provided type and identifier
   */
  const buildUrl = useCallback(
    ({ type, identifier, requestId }: BuildUrlOptions = {}) => {
      let basePath;

      // Dash to Hub links need special ?shHashRedirect query param to preserve and carry the
      // SuperHub hash fragment to the Hub page
      if (isHubGoLink) {
        const shHash = `%23/my-developers${!isSetupComplete ? '/setup' : ''}`;
        basePath = `/hub-go/${project.subdomain}?shHashRedirect=${shHash}`;
      } else {
        basePath = isHub ? '/my-developers' : `${baseUrl}/metrics/developers`;
      }

      if (!type || !identifier) {
        return basePath;
      }

      const routes = {
        [MyDevelopersSubrouteType.Segment]: `${basePath}/segment/${identifier}`,
        [MyDevelopersSubrouteType.User]: `${basePath}/user/${identifier}`,
        [MyDevelopersSubrouteType.Key]: (() => {
          const encodedIdentifier = base64url.encode(identifier);
          const keyPath = `${basePath}/key/${encodedIdentifier}`;
          return requestId ? `${keyPath}?requestId=${requestId}` : keyPath;
        })(),
      };

      return routes[type] || basePath;
    },
    [baseUrl, isHub, isHubGoLink, isSetupComplete, project.subdomain],
  );

  return {
    buildUrl,
    navigate,
  };
}
