import type { FC } from 'react';

import React, { useMemo } from 'react';

import Button from '@ui/Button';
import DateLine from '@ui/DateLine';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu from '@ui/Menu';
import MenuDivider from '@ui/Menu/Divider';
import MenuItem from '@ui/Menu/Item';

import classes from './style.module.scss';

export const UNSELECTED_LABEL = 'Your API Key';

export interface TokenObject {
  _id: string;
  label?: string;
  lastAccessedOn: Date;
  token: string;
}

interface APIKeyDropdownProps {
  apiKeyOptions: TokenObject[];
  className?: string;
  isHub?: boolean;
  onChange: (token: string) => void;
  selectedAPIKey: string;
  subdomain: string;
  version: string;
}

function getLabel(token?: TokenObject) {
  return token ? token.label || `••••${token.token.substr(-5)}` : UNSELECTED_LABEL;
}

const APIKeyDropdown: FC<APIKeyDropdownProps> = ({
  apiKeyOptions,
  className,
  onChange,
  selectedAPIKey,
  subdomain,
  version,
  isHub = false,
}) => {
  const selectedApiKeyObject = apiKeyOptions?.find(token => token.token === selectedAPIKey);

  const manageApiKeysLink = useMemo(() => {
    if (isHub) {
      return '/dash?to=api-key';
    }

    const queryParam = apiKeyOptions?.length ? '' : '?create=Metrics';
    return `/project/${subdomain}/v${version}/api-key${queryParam}`;
  }, [apiKeyOptions?.length, isHub, subdomain, version]);

  return (
    <Dropdown
      appendTo={() => document.body}
      className={[classes.APIKeyDropdown, className].filter(Boolean).join(' ')}
      clickInToClose
      offset={[0, -4]}
      sticky
    >
      <Button className={classes['APIKeyDropdown-button']} dropdown size="sm">
        {getLabel(selectedApiKeyObject)}
      </Button>
      <Menu role="menu" theme="dark">
        <div className={classes['APIKeyDropdown-keys']}>
          {!apiKeyOptions?.length && (
            <MenuItem focusable={false}>
              <div className={classes['APIKeyDropdown-details']}>No API Keys for this project</div>
            </MenuItem>
          )}
          {apiKeyOptions?.map(token => {
            const displayLabel = getLabel(token);

            return (
              <MenuItem
                key={`tokens-${token._id}`}
                active={token?.token === selectedAPIKey}
                onClick={() => {
                  onChange(token.token);
                }}
              >
                <div>
                  <div>{displayLabel}</div>
                  <div className={classes['APIKeyDropdown-details']}>
                    {!!token.lastAccessedOn && (
                      <DateLine absoluteDates={true} prefix="Used" time={token.lastAccessedOn} />
                    )}
                    {!token.lastAccessedOn && 'Never Used'}
                  </div>
                </div>
              </MenuItem>
            );
          })}
        </div>
        <MenuDivider />
        <MenuItem
          className={classes['APIKeyDropdown-link']}
          href={manageApiKeysLink}
          icon={<Icon aria-hidden="true" name="key" />}
          TagName="a"
          target={isHub ? '_blank' : undefined}
        >
          <Flex justify="between">
            <span>Manage API Keys</span>
            {!isHub && <Icon name="arrow-right" />}
          </Flex>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

export default APIKeyDropdown;
