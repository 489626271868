import type { MetricsPageRouteParams } from './types';

import base64url from 'base64url';
import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';
import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext } from '@core/context';
import usePrevious from '@core/hooks/usePrevious';
import { toKeyString } from '@core/utils/metrics';

import useAsyncRouteState from '@routes/Dash/Project/Metrics/hooks/useAsyncRouteState';
import * as routeOptions from '@routes/Dash/Project/Metrics/routes';

import { dateRangeDefaults } from './constants';

import { useMetricsStore } from '.';

const ALLOWED_GROUP_BYS = [
  'company',
  'email',
  'eventName',
  'groupId',
  'path',
  'period',
  'searchTerm',
  'status',
  'statusCategory',
  'type',
  'url',
  'useragent',
];

export interface ConnectMetricsStoreToRouterProps {
  children: React.ReactNode;
}

/**
 * Middleware component that listens for browser router updates and continually
 * updates the store state whenever the route changes.
 */
export function ConnectMetricsStoreToRouter({ children }: ConnectMetricsStoreToRouterProps) {
  const [
    shouldLimitDateRanges,
    updateRoute,
    updateShowDemoData,
    updateQuery,
    updateMetricsPageConfig,
    metricsPageConfig,
  ] = useMetricsStore(s => [
    s.shouldLimitDateRanges(),
    s.updateRoute,
    s.updateShowDemoData,
    s.updateQuery,
    s.updateMetricsPageConfig,
    s.metricsPageConfig,
  ]);

  const { pathname, search } = useLocation();
  const prevPathname = usePrevious(pathname) || '';
  const { slug } = useParams<MetricsPageRouteParams>();
  const routeState = useAsyncRouteState(slug);
  const { name } = useContext(ConfigContext) as ConfigContextValue;

  const isHub = name === 'Hub';

  // The Metrics page route slug determines the page config options
  // so we'll update the store whenever slug changes as soon as we have it
  useEffect(() => {
    if (slug) {
      const route = cloneDeep(routeOptions?.[toKeyString(slug)]) || {};
      updateMetricsPageConfig({ ...cloneDeep(route) });
    }
  }, [slug, updateMetricsPageConfig]);

  // Maintain current route information in the store
  useEffect(() => {
    /**
     * For SH demo mode, we'll monitor for ?demo=true query param
     * and update the demoData flag in store accordingly
     */
    if (isHub) {
      const params = new URLSearchParams(window.location.search);
      const demoSearchParam = params.get('demo');
      updateShowDemoData(demoSearchParam === 'true');
    }

    updateRoute({
      pathname,
      search,
      prevPathname,
    });
  }, [isHub, pathname, search, prevPathname, updateRoute, updateShowDemoData]);

  /**
   * Support query filtering for groupBy and email params via query params
   * eg: `/page-views?userSearch=tony@readme.io`
   *
   * Note: we only want this effect to run whenever the routeState's endpoint updates (page load or navigation to new page)
   * since it's monitoring ?userSearch and ?groupBy params that should only be applied once per page load.
   */
  useEffect(() => {
    if (!search) return;

    const searchParams = new URLSearchParams(search);
    const userSearch = searchParams.get('userSearch') ?? '';
    const groupBy = searchParams.get('groupBy') ?? '';
    const isEncoded = searchParams.get('encoded') === 'true';

    // Accept one or more groupBy params, and filter out any that aren't allowed
    const validGroupBys = groupBy.split(',').filter(param => ALLOWED_GROUP_BYS.includes(param));

    // groupBy params only should be applied to graphQuery (used in /list and /historical fetches)
    if (!!groupBy && routeState?.endpoint && validGroupBys.length) {
      const query = new URLSearchParams(metricsPageConfig?.graph.query);
      const base = metricsPageConfig?.graph?.groupByBase || false;
      const uniqueGroupBys = new Set<string>();

      // Add the valid groupBy params to the set
      validGroupBys.forEach(param => uniqueGroupBys.add(param));

      if (base) uniqueGroupBys.add(base);

      // Reset the ?groupBy param, and append the unique groupBy params
      query.delete('groupBy');
      uniqueGroupBys.forEach(param => query.append('groupBy', param));

      // TODO: see if we can avoid using qs here
      updateQuery('graphQuery', qs.parse(query.toString()) as Record<string, string>);
    }

    // userSearch param should only be applied to query
    if (userSearch) {
      updateQuery('query', { userSearch: isEncoded ? base64url.decode(userSearch) : userSearch });
    }
  }, [routeState?.endpoint, search, updateQuery, updateMetricsPageConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  // Reset date range selection when navigating away from My Developers page
  useEffect(() => {
    if (prevPathname.includes('/metrics/developers') && !pathname.includes('/metrics/developers')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { enabled, ...queryPayload } = shouldLimitDateRanges ? dateRangeDefaults.day : dateRangeDefaults.month;
      updateQuery('query', queryPayload);
    }
  }, [pathname, prevPathname, shouldLimitDateRanges, updateQuery]);

  return <>{children}</>;
}
