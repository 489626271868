import type { UserMetricDataProps } from '../types/props';
import type { HTTPMethod } from '@readme/iso';

import { getStatusCode } from '@readme/http-status-codes';
import qs from 'qs';
import React, { useCallback, useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import { useMetricsStore } from '@core/store';
import { stringifyOptions } from '@core/store/Metrics/constants';
import { formatMinutesToReadableString } from '@core/utils/metrics';

import APIMethod from '@ui/API/Method';
import Box from '@ui/Box';
import HTTPStatus from '@ui/HTTPStatus';
import Icon from '@ui/Icon';
import ObfuscatedAPIKey from '@ui/ObfuscatedAPIKey';
import Tooltip from '@ui/Tooltip';

import UserMetric from '../UserMetric';
import UserProfileCard from '../UserProfileCard';

import classes from './style.module.scss';

interface TimeToDataProps {
  data: { timeToFirstCall: number };
  error: unknown;
  headers: Record<string, string>;
  isLoading: boolean;
  isValidating: boolean;
}
interface UserErrorDataProps {
  data: { errorRate: number };
  error: unknown;
  headers: Record<string, string>;
  isLoading: boolean;
  isValidating: boolean;
}
interface UserOverviewDataProps {
  data: { avatar: string; email: string; label: string; lastActive?: Date };
  error: unknown;
  headers: Record<string, string>;
  isLoading: boolean;
  isValidating: boolean;
}
interface UserInsightDataProps {
  data: {
    topApiKeys: { apiKey: string; percent: number }[];
    topEndpoints: { method: HTTPMethod; path: string; percent: number }[];
    topErrors: { percent: number; status: number }[];
  };
  error: unknown;
  headers: Record<string, string>;
  isLoading: boolean;
  isValidating: boolean;
}

interface Props {
  /** Whether panel is being used in Superhub (so Box and subcomponents can have dark-on-black styling) */
  isSuperhub?: boolean;
}

function Sidepanel({ isSuperhub }: Props) {
  const bem = useClassy(classes, 'Sidepanel');
  const [isUsageReady, resetQuery, updateQuery, query, showDemoData] = useMetricsStore(s => [
    s.isUsageReady,
    s.resetQuery,
    s.updateQuery,
    s.query,
    s.showDemoData,
  ]);

  const isReadyToFetchData = isUsageReady && !!query.email;

  const userBaseRoute = useMemo(() => `users/${query.email || ''}`, [query.email]);

  const timeToQuery = useMemo(() => {
    return qs.stringify(
      {
        email: query.email,
        includeDelta: false,
        demo: showDemoData,
      },
      stringifyOptions,
    );
  }, [showDemoData, query.email]);

  const overviewQuery = useMemo(() => {
    return qs.stringify(
      {
        demo: showDemoData,
      },
      stringifyOptions,
    );
  }, [showDemoData]);

  const sidePanelSectionQuery = useMemo(() => {
    return qs.stringify(query, stringifyOptions);
  }, [query]);

  // Store all data from the API calls
  const timeToData: TimeToDataProps = useMetricsAPI(`users/time-to-first-call?${timeToQuery}`, isReadyToFetchData);
  const userOverviewData: UserOverviewDataProps = useMetricsAPI(
    `${userBaseRoute}/overview?${overviewQuery}`,
    isReadyToFetchData,
  );
  const userErrorData: UserErrorDataProps = useMetricsAPI(
    `${userBaseRoute}/error-rate?${sidePanelSectionQuery}`,
    isReadyToFetchData,
  );
  const userInsightData: UserInsightDataProps = useMetricsAPI(
    `${userBaseRoute}/top-request-insights?${sidePanelSectionQuery}`,
    isReadyToFetchData,
  );

  // Prevent non int values from being displayed
  const checkForNullPercent = (value: number) => {
    return typeof value === 'number' ? `${value}%` : '';
  };

  const OverViewToolTip = () => {
    return (
      <Tooltip
        content={
          <div className={bem('-tooltip')}>
            <strong>Time to First 200: </strong>
            <span className={bem('-tooltip-text')}>
              Calculated from the time a user is first seen in your docs to their first successful API Call
            </span>
          </div>
        }
      >
        <Icon color="gray60" name="info" />
      </Tooltip>
    );
  };

  const ErrorRateToolTip = () => {
    return (
      <Tooltip
        content={
          <div className={bem('-tooltip')}>
            <strong>Error Rate: </strong>
            <span className={bem('-tooltip-text')}>The percent of all API Calls that return errors</span>
          </div>
        }
      >
        <Icon color="gray60" name="info" />
      </Tooltip>
    );
  };

  // Formate the data to display in a DataArea
  const overviewData: UserMetricDataProps[] = [
    {
      label: 'Time to 200',
      value: `${formatMinutesToReadableString(timeToData.data?.timeToFirstCall ?? 0)}`,
      icon: <OverViewToolTip />,
    },
    {
      label: 'Error Rate',
      value: checkForNullPercent(userErrorData.data?.errorRate) || '0.00%',
      icon: <ErrorRateToolTip />,
    },
  ];

  const topEndpoints = useMemo<UserMetricDataProps[]>(() => {
    return userInsightData.data?.topEndpoints?.map(e => ({
      label: e.path,
      value: checkForNullPercent(e.percent),
      icon: <APIMethod type={e.method} />,
    }));
  }, [userInsightData.data]);

  const topErrors = useMemo<UserMetricDataProps[]>(() => {
    return userInsightData.data?.topErrors?.map(e => ({
      label: ` - ${getStatusCode(e.status).message}`,
      value: checkForNullPercent(e.percent),
      icon: <HTTPStatus className={bem('&-status')} status={e.status} />,
    }));
  }, [bem, userInsightData.data?.topErrors]);

  const topAPIKeys = useMemo<UserMetricDataProps[]>(() => {
    return userInsightData.data?.topApiKeys?.map(k => ({
      label: (
        <ObfuscatedAPIKey
          allowCopy={false}
          allowExpansion={false}
          // eslint-disable-next-line readme-internal/no-legacy-project-api-keys
          apiKey={k.apiKey}
          conceal="before"
          displayLength={4}
        />
      ),
      value: checkForNullPercent(k.percent),
      icon: <Icon color="gray60" name="key" />,
    }));
  }, [userInsightData.data?.topApiKeys]);

  // Group by functions for the buttons
  const groupByEndpoints = useCallback(() => {
    updateQuery('graphQuery', { groupBy: ['period', 'url'] });
    resetQuery('tableQuery');
  }, [resetQuery, updateQuery]);

  const groupByErrors = useCallback(() => {
    updateQuery('graphQuery', { groupBy: ['period', 'status'], subset: 'error' });
    resetQuery('tableQuery');
  }, [resetQuery, updateQuery]);

  const groupByAPIKeys = useCallback(() => {
    updateQuery('graphQuery', { groupBy: ['period', 'groupId'] });
    resetQuery('tableQuery');
  }, [resetQuery, updateQuery]);

  return (
    <Box className={bem('&')} kind="card" theme={isSuperhub ? 'dark-on-black' : undefined}>
      <UserProfileCard isLoading={userOverviewData.isLoading} isSuperhub={isSuperhub} user={userOverviewData.data} />
      <UserMetric
        data={overviewData}
        iconPosition="right"
        isLoading={timeToData.isLoading || userErrorData.isLoading}
        isSuperhub={isSuperhub}
        text={{ errorText: 'No Overview', title: 'Overview' }}
      />
      {!!topEndpoints?.length && (
        <UserMetric
          data={topEndpoints}
          isLoading={userInsightData.isLoading}
          isSuperhub={isSuperhub}
          onClick={groupByEndpoints}
          text={{ btnText: 'All Endpoints', errorText: 'No Endpoints', title: 'Top Endpoints' }}
        />
      )}
      {!!topErrors?.length && (
        <UserMetric
          data={topErrors}
          isLoading={userInsightData.isLoading}
          isSuperhub={isSuperhub}
          onClick={groupByErrors}
          text={{ btnText: 'All Errors', errorText: 'No Errors', title: 'Top Errors' }}
        />
      )}
      {!!topAPIKeys?.length && (
        <UserMetric
          data={topAPIKeys}
          isLoading={userInsightData.isLoading}
          isSuperhub={isSuperhub}
          onClick={groupByAPIKeys}
          text={{ btnText: 'All API Keys', errorText: 'No API Keys', title: 'Top API Keys' }}
        />
      )}
    </Box>
  );
}

export default Sidepanel;
