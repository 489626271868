import React, { useCallback, useEffect, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import useLocalStorage from '@core/hooks/useLocalStorage';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Modal from '@ui/Modal';
import type BaseModal from '@ui/Modal';

import styles from './index.module.scss';
import OwlbertWelcomePNG from './owlbert-welcome.webp';

export default function WelcomeModal() {
  const bem = useClassy(styles, 'WelcomeModal');
  const modalRef = useRef<BaseModal>(null);
  const uid = useUniqueId('WelcomeModal');

  // Generate a unique ID for the modal root container.
  const modalTargetId = uid('modal-root');

  const storage = useLocalStorage({ prefix: 'welcomeModal' });

  const [projectId, isMigrated] = useProjectStore(s => [s.data.id, s.data.refactored.migrated === 'successful']);

  // Use project id to generate unique storage key
  // (want to show modal on a per-project basis)
  const storageKey = `${projectId}-dismissed`;

  useEffect(() => {
    // Only show Welcome Modal if migration object exists on current project and user hasn't dismissed the modal
    // local storage vals get converted to strings
    const dismissedModal = storage.getItem(storageKey) === 'true';
    if (modalRef.current && isMigrated && !dismissedModal) {
      modalRef.current.toggle(true);
    }
  }, [modalRef, storage, storageKey, isMigrated]);

  const handleClose = useCallback(() => {
    storage.setItem(storageKey, true);
    modalRef.current?.toggle(false);
    // want to omit storage as a dep to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storageKey]);

  return (
    <>
      <Modal
        ref={modalRef}
        className={bem()}
        focusLockClassName={bem('-overflow-visible')}
        onClose={handleClose}
        target={`#${modalTargetId}`}
        verticalCenter
      >
        <div className={bem('-container')}>
          <div className={bem('-body')}>
            <Flex align="center" justify="center" layout="col">
              <div className={bem('-image-wrapper')}>
                <img alt="Owlbert Welcome" src={OwlbertWelcomePNG} />
              </div>
              <h1 className={bem('-title')}>Welcome to the New ReadMe</h1>
              <div className={bem('-text')}>
                Enjoy our sleeker, unified UI, an MDX-powered editor, and bi-directional syncing with Git. <br />
                Take it for a spin!{' '}
                <a
                  href=" https://docs.readme.com/main/docs/welcome-to-readme-refactored"
                  rel="noreferrer"
                  target="_blank"
                >
                  Learn more <Icon name="arrow-up-right" />
                </a>
              </div>
            </Flex>
          </div>
          <div className={bem('-divider')} />
          <div className={bem('-footer')}>
            <Button kind="contrast" onClick={handleClose} text>
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <div className="ModalWrapper" id={modalTargetId} />
    </>
  );
}
