import Tippy from '@tippyjs/react';
import base64url from 'base64url';
import pluralize from 'pluralize';
import qs from 'qs';
import React, { useState, useRef, useMemo, useContext, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import { BaseUrlContext } from '@core/context';
import {
  MetricsSearchSectionType,
  MyDevelopersSubrouteType,
  MetricsSearchType,
  MyDevelopersSearchSectionType,
} from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import useMyDevelopers from '@core/hooks/useMyDevelopers';
import { useMetricsStore } from '@core/store';
import type { MetricsSearchResponse, MyDevelopersSearchResponse, MyDevelopersSearchResult } from '@core/types/metrics';
import { getElapsed, slugify } from '@core/utils/metrics';
import prettyNumber from '@core/utils/prettyNumber';

import Box from '@ui/Box';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuItem, MenuHeader, MenuDivider } from '@ui/Menu';
import ObfuscatedAPIKey from '@ui/ObfuscatedAPIKey';
import Spinner from '@ui/Spinner';
import Timestamp from '@ui/Timestamp';

import styles from './style.module.scss';

interface Item {
  key?: string;
  label: string;
  metric: MetricsSearchType;
  type: string;
}

interface ResultSection {
  items: Item[];
  key: MetricsSearchSectionType;
  name: string;
}

interface MyDeveloperResultSection {
  key: MyDevelopersSearchSectionType;
  name: string;
}

const resultSections: ResultSection[] = [
  {
    name: 'API Metrics',
    key: MetricsSearchSectionType.ApiMetrics,
    items: [
      { metric: MetricsSearchType.ApiCalls, key: 'requests', label: 'API Calls', type: 'Call' },
      { metric: MetricsSearchType.TopEndpoints, key: 'endpoints', label: 'Top Endpoints', type: 'Endpoint' },
      { metric: MetricsSearchType.ApiErrors, key: 'errors', label: 'API Errors', type: 'Error' },
    ],
  },
  {
    name: 'Doc Metrics',
    key: MetricsSearchSectionType.DocMetrics,
    items: [
      { metric: MetricsSearchType.PageViews, label: 'Page Views', type: 'View' },
      { metric: MetricsSearchType.NewUsers, label: 'New Users', type: 'User' },
      { metric: MetricsSearchType.PageQuality, label: 'Page Quality', type: 'Vote' },
      { metric: MetricsSearchType.Search, label: 'Search', type: 'Search' },
    ],
  },
  {
    name: 'My Developers',
    key: MetricsSearchSectionType.Developers,
    items: [
      { metric: MetricsSearchType.ApiKeys, label: 'API Keys', type: 'Key' },
      { metric: MetricsSearchType.ApiKeyInsightCalls, key: 'requests', label: 'API Calls', type: 'Call' },
      { metric: MetricsSearchType.Users, label: 'Users', type: 'User' },
    ],
  },
];

const myDeveloperResultSections: MyDeveloperResultSection[] = [
  {
    name: 'API Keys',
    key: MyDevelopersSearchSectionType.ApiKeys,
  },
  {
    name: 'Requests',
    key: MyDevelopersSearchSectionType.Requests,
  },
  {
    name: 'Users',
    key: MyDevelopersSearchSectionType.Users,
  },
];

export interface MetricsSearchProps {
  isMyDevelopers?: boolean;
  isSuperhub?: boolean;
  isUserPage?: boolean;
}

const MetricsSearch = ({ isMyDevelopers, isUserPage, isSuperhub }: MetricsSearchProps) => {
  const bem = useClassy(styles, 'MetricsSearch');
  const baseUrl = useContext(BaseUrlContext);

  const [
    updateQuery,
    resetQuery,
    developmentData,
    includeTryItNow,
    selectedDateRangeKey,
    query,
    filters,
    myDevSelectedDateRangeKey,
    rangeQueryParams,
    updateFilters,
  ] = useMetricsStore(s => [
    s.updateQuery,
    s.resetQuery,
    s.developmentData,
    s.includeTryItNow,
    s.selectedDateRangeKey,
    s.query,
    s.myDevelopers.filters,
    s.myDevelopers.getSelectedDateRangeKey(),
    s.myDevelopers.getRangeQueryParams(),
    s.myDevelopers.updateFilters,
  ]);

  const location = useLocation();
  const { buildUrl, navigate } = useMyDevelopers();

  const userSearch = (isMyDevelopers ? filters?.userSearch : query.userSearch) || '';
  const inputRef = useRef<HTMLInputElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [search, setSearch] = useState(userSearch);
  const [isOpen, setIsOpen] = useState(false);

  // Update search if userSearch changes in context
  useEffect(() => {
    setSearch(userSearch);
  }, [userSearch]);

  useEffect(() => {
    // On unmount, reset search back to null
    return () => {
      updateQuery('query', { userSearch: null });
    };
  }, [updateQuery]);

  const params = useMemo(() => {
    const parsedOpts = qs.parse(rangeQueryParams);

    if (isMyDevelopers) {
      return {
        demo: parsedOpts.demo === 'true',
        rangeEnd: parsedOpts.rangeEnd,
        rangeLength: parsedOpts.rangeLength ? Number(parsedOpts.rangeLength) : undefined,
        rangeStart: parsedOpts.rangeStart,
        resolution: parsedOpts.resolution,
        userSearch: search,
      };
    }

    return {
      development: developmentData,
      rangeEnd: query.rangeEnd,
      rangeLength: query.rangeLength ? Number(query.rangeLength) : undefined,
      rangeStart: query.rangeStart,
      resolution: query.resolution,
      tryItNow: includeTryItNow,
      demo: parsedOpts.demo === 'true',
      userSearch: search,
    };
  }, [developmentData, includeTryItNow, isMyDevelopers, query, rangeQueryParams, search]);

  const searchUrl = isMyDevelopers ? 'developers/search' : 'project/search';
  const { data, isLoading, error } = useMetricsAPI<MetricsSearchResponse | MyDevelopersSearchResponse>(
    searchUrl,
    !!search,
    {
      method: 'POST',
      body: params,
    },
  );

  const handleBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    const isNextFocusWithinInputOnClear = ev.relatedTarget?.classList.contains('InputParent-clearBtn');
    const isNextFocusWithinMenu =
      menuRef.current?.contains(ev.relatedTarget) || ev.relatedTarget?.contains(menuRef.current);
    if (!isNextFocusWithinMenu && !isNextFocusWithinInputOnClear) {
      setIsOpen(false);
    }
  };

  const updateSearch = useCallback(
    (newSearch: string) => {
      const val = newSearch.trim();
      setSearch(val);

      /**
       * If we're on the user page, we only want the search to update the search results and not the rest of the User page's queries
       */
      if (isUserPage) return;

      /**
       * When searching in My Developers, we want only want search to update MyDev filters
       * The only exception is for valid UUIDs pasted in because we don't have ability to filter down by request ID
       */
      if (isMyDevelopers) {
        if (!uuidValidate(val)) {
          updateFilters({ userSearch: val || null, page: 0 });
        }
        return;
      }

      // Reset any table query params and update base query to include userSearch
      resetQuery('tableQuery');

      updateQuery('query', {
        userSearch: val || null,
      });
    },
    [isMyDevelopers, isUserPage, resetQuery, updateFilters, updateQuery],
  );

  const handleChange = useDebounced(updateSearch, 500);

  const handleNavigate = useCallback(
    (url: string) => {
      setIsOpen(false);

      navigate(url);

      if (isMyDevelopers) {
        updateFilters({ userSearch: null, page: 0 });
      }
    },
    [isMyDevelopers, navigate, updateFilters],
  );

  const onResultClick = useCallback(
    (item: Item, count: number) => {
      const { label, metric } = item;

      let searchValue = search;
      let isEncoded = false;

      const looksLikeAPIKey = searchValue.startsWith('sha512-');
      const looksLikeEmail = searchValue.includes('@');

      /**
       * If searchValue begins with sha-512, encode it and send param indicating it's encoded
       * This is because API keys need to be encoded and decoded as URL safe base64 strings
       */
      if (looksLikeAPIKey) {
        searchValue = base64url.encode(searchValue);
        isEncoded = true;
      }

      let url: string;

      // Build URL based on metric type
      // Note: /hub-go links won't support ?userSearch param for now
      switch (metric) {
        case MetricsSearchType.ApiKeys: {
          // Navigate directly to key page if there's only one result
          if (looksLikeAPIKey && count === 1) {
            // Note: Encoding/Decoding happens automatically in routing for /key/:key route
            url = buildUrl({ type: MyDevelopersSubrouteType.Key, identifier: search });
          } else {
            const base = buildUrl();
            url = `${base}?userSearch=${searchValue}${isEncoded ? '&encoded=true' : ''}`;
          }
          break;
        }
        case MetricsSearchType.ApiKeyInsightCalls: {
          const base = buildUrl();
          url = `${base}?userSearch=${searchValue}${isEncoded ? '&encoded=true' : ''}`;
          break;
        }
        case MetricsSearchType.Users: {
          if (looksLikeEmail && count === 1) {
            // Note: Encoding/Decoding happens automatically in routing for /user/:email route
            url = buildUrl({ type: MyDevelopersSubrouteType.User, identifier: search });
          } else {
            const base = buildUrl();
            url = `${base}?userSearch=${searchValue}`;
          }
          break;
        }
        default: {
          const route = slugify(label);
          url = `${baseUrl}/metrics/${route}?userSearch=${searchValue}${isEncoded ? '&encoded=true' : ''}`;
          break;
        }
      }

      handleNavigate(url);
    },
    [baseUrl, buildUrl, handleNavigate, search],
  );

  const onMyDevelopersResultClick = useCallback(
    (item: MyDevelopersSearchResult) => {
      const keyInsightsUrl = buildUrl({
        type: MyDevelopersSubrouteType.Key,
        identifier: item.apiKey, // eslint-disable-line readme-internal/no-legacy-project-api-keys
        requestId: item.id,
      });
      handleNavigate(keyInsightsUrl);
    },
    [buildUrl, handleNavigate],
  );

  const dateRangeText = useMemo(() => {
    const dateRangeKey = isMyDevelopers ? myDevSelectedDateRangeKey : selectedDateRangeKey;
    return dateRangeKey !== 'custom' ? `In the last ${dateRangeKey}` : 'In this period';
  }, [isMyDevelopers, myDevSelectedDateRangeKey, selectedDateRangeKey]);

  const searchMenu = useMemo(() => {
    if (!search) return null;

    if (isLoading) {
      return (
        <Box className={bem('-loading')} kind="card">
          <Spinner />
        </Box>
      );
    }

    if (error) {
      return (
        <Box className={bem('-no-results')} kind="card">
          <span className={bem('-no-results-text')}>Something went wrong, please try again.</span>
        </Box>
      );
    }

    // Loop over all data elements and see if any items have a value > 0
    // The data shape is different for My Developers, so we need to handle that separately
    const hasMyDevelopersResults =
      isMyDevelopers &&
      !!(data[MyDevelopersSearchSectionType.ApiKeys].length || data[MyDevelopersSearchSectionType.Requests].length);
    const hasBaseResults = Object.values(data || {}).some(obj =>
      Object.values(obj || {}).some(val => typeof val === 'number' && val > 0),
    );

    const hasResults = isMyDevelopers ? hasMyDevelopersResults : hasBaseResults;

    if (!hasResults) {
      return (
        <Box className={bem('-no-results')} kind="card">
          <Flex align="center" className={bem('-no-results-text')} gap="0" justify="center" layout="col">
            <span>No results found</span>
            Try a different time range?
          </Flex>
        </Box>
      );
    }

    return (
      <Menu
        ref={menuRef}
        className={bem('-menu')}
        onBlur={handleBlur}
        onMouseLeave={() => {
          if (document.activeElement !== inputRef.current) setIsOpen(false);
        }}
      >
        {isMyDevelopers
          ? myDeveloperResultSections.map(({ name, key }) => {
              const items = data[key] as MyDevelopersSearchResponse['apiKeys'] | MyDevelopersSearchResponse['requests'];
              const sectionHasData = items?.length > 0;

              if (!sectionHasData) return null;

              return (
                <React.Fragment key={name}>
                  <MenuHeader>{name}</MenuHeader>
                  {items.map(({ apiKey, email, id, lastRequest }, index) => {
                    const icon = key === MyDevelopersSearchSectionType.ApiKeys ? 'key' : 'apilogs';
                    const value =
                      key === MyDevelopersSearchSectionType.ApiKeys ? (
                        <ObfuscatedAPIKey
                          allowCopy={false}
                          allowExpansion={false}
                          apiKey={apiKey}
                          conceal="before"
                          displayLength={4}
                        />
                      ) : (
                        id
                      );

                    return (
                      <MenuItem
                        key={`MenuItem-${index}`}
                        className={bem('-menu-item')}
                        onClick={() => {
                          onMyDevelopersResultClick({ apiKey, email, id, lastRequest });
                        }}
                      >
                        <Flex align="end" gap="xs" justify="start" style={{ width: '100%' }}>
                          <Flex align="center" className={bem('-value')} gap={0} justify="start">
                            <Icon name={icon} />
                            <span className={bem('-value-text')} title={id || apiKey}>
                              {value}
                            </span>
                          </Flex>

                          <Flex align="center" className={bem('-details')} gap="xs" justify="between">
                            <span className={bem('-timestamp')}>
                              <Timestamp
                                formatter={date => getElapsed(date.toISOString(), { includeAgo: true })}
                                value={new Date(lastRequest)}
                              />
                            </span>
                            <span className={bem('-email')} title={email}>
                              {email}
                            </span>
                          </Flex>
                        </Flex>
                      </MenuItem>
                    );
                  })}
                  <MenuDivider />
                </React.Fragment>
              );
            })
          : resultSections.map(({ name, key, items }) => {
              const sectionHasData = Object.values(data[key] || {}).some(val => typeof val === 'number' && val > 0);

              if (!sectionHasData) return null;

              return (
                <React.Fragment key={name}>
                  <MenuHeader>{name}</MenuHeader>
                  {items.map(({ label, metric, key: metricKey, type }) => {
                    const count = data[key][metricKey || metric];
                    const isOnMetricsRoute = name !== 'My Developers' && location.pathname.includes(slugify(label));

                    // Don't show menu item if count is 0 or if we're already on the route
                    if (!count) return null;

                    const prettyWithUnits = `${prettyNumber(count)} ${pluralize(type, count)}`;

                    return (
                      <MenuItem
                        key={`MenuItem-${name}-${metricKey || metric}`}
                        className={bem('-menu-item', isOnMetricsRoute && '-menu-item_disabled')}
                        disabled={isOnMetricsRoute}
                        onClick={() => onResultClick({ label, metric, key: metricKey, type }, count)}
                      >
                        <Flex align="baseline" justify="between">
                          <Flex align="center" className={bem('-menu-item-label')} gap="xs">
                            {label}
                            <Icon className={bem('-menu-item-link-icon')} name="arrow-right" />
                          </Flex>
                          <span className={bem('-units')} title={prettyWithUnits}>
                            {prettyWithUnits}
                          </span>
                        </Flex>
                      </MenuItem>
                    );
                  })}
                  <MenuDivider />
                </React.Fragment>
              );
            })}
        <MenuItem className={bem('-date-menu-item')} description={dateRangeText} focusable={false} />
      </Menu>
    );
  }, [
    bem,
    data,
    error,
    isLoading,
    isMyDevelopers,
    location,
    onMyDevelopersResultClick,
    onResultClick,
    search,
    dateRangeText,
  ]);

  const placeholder = useMemo(() => {
    if (isMyDevelopers) {
      return isSuperhub ? 'See Usage by Email, API Key or Company' : 'Filter users, API Keys, companies, request ID';
    }

    return 'Users, API Keys, Companies';
  }, [isMyDevelopers, isSuperhub]);

  return (
    /* Input and Tippy need to be co-located in source order for nice tab focusing, so wrap them in a div */
    <div className={bem('&', isMyDevelopers && '_wide', isSuperhub && '_superhub')}>
      <Input
        ref={inputRef}
        className={bem('-input', isSuperhub && '-input_superhub')}
        onBlur={handleBlur}
        onChange={ev => handleChange(ev.target.value)}
        onClear={() => handleChange('')}
        onFocus={() => setIsOpen(true)}
        placeholder={placeholder}
        size="sm"
        type="search"
        value={search}
      />
      <Tippy
        appendTo="parent"
        arrow={false}
        content={searchMenu}
        interactive
        maxWidth={400}
        offset={[0, 4]}
        onClickOutside={() => {
          inputRef?.current?.blur();
          setIsOpen(false);
        }}
        placement="bottom-end"
        reference={inputRef}
        visible={isOpen}
      />
    </div>
  );
};

export default MetricsSearch;
