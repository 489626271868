import Tippy from '@tippyjs/react';
import isEqual from 'lodash/isEqual';
import React, { useState, useRef, useEffect, useCallback } from 'react';

import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import useUniqueId from '@core/hooks/useUniqueId';

import type { InputProps } from '@ui/Input';
import Input from '@ui/Input';
import Menu, { MenuItem, MenuHeader } from '@ui/Menu';

import classes from './style.module.scss';
import useActiveIndex from './useActiveIndex';

type Props = InputProps & {
  /**
   * Flag that when true will filter the options in the listbox when the input value changes
   */
  filterSuggestions?: true;
  /**
   * Label for the listbox `MenuHeader` component
   */
  menuHeader?: string;
  /**
   * Array of options that appear as `MenuItem` components in the listbox
   */
  menuOptions: string[];
  /**
   * Callback invoked for input's blur event
   */
  onBlur?: (value: string) => void;
  /**
   * Callback invoked when input's value changes
   */
  onChange?: (value: string) => void;
};

function ComboBox({
  filterSuggestions = true,
  menuHeader,
  menuOptions,
  onBlur,
  onChange,
  value: providedValue,
  ...attrs
}: Props) {
  const bem = useClassy(classes, 'ComboBox');
  const uid = useUniqueId('ComboBox');
  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<string>((providedValue as string) || '');
  const [options, setOptions] = useState(menuOptions);
  const deferAutoOpen = useRef(false);

  const { activeIdx, goToFirst, gotoNext, goToPrevious, updateActiveIndexList, clearActiveIndex } =
    useActiveIndex(menuOptions);

  const debouncedFilterOptions = useDebounced((val: string) => {
    const filteredOptions = menuOptions.filter(str => str.toLowerCase().includes(val.toLowerCase()));
    if (!isEqual(options, filteredOptions)) {
      setOptions(filteredOptions);
      updateActiveIndexList(filteredOptions);
      goToFirst();
    }
  }, 100);

  useEffect(() => {
    if (filterSuggestions) debouncedFilterOptions(value);
  }, [filterSuggestions, debouncedFilterOptions, value]);

  useEffect(() => {
    if (typeof onChange === 'function') onChange(value);
  }, [onChange, value]);

  const handleBlur = useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      clearActiveIndex();
      if (typeof onBlur === 'function') onBlur(ev.target.value);
    },
    [clearActiveIndex, onBlur],
  );

  const handleChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      if (!isOpen) setIsOpen(true);
      setValue(ev.target.value);

      if (typeof onChange === 'function') onChange(ev.target.value);
    },
    [isOpen, onChange],
  );

  const selectValue = useCallback(
    (idx?: number) => {
      const nextIdx = idx != null ? idx : activeIdx;
      if (nextIdx != null) {
        setValue(options[nextIdx]);
        setIsOpen(false);
        clearActiveIndex();

        deferAutoOpen.current = true; // temporarily disable opening menu on focus
        inputRef.current?.focus();
      }
    },
    [activeIdx, clearActiveIndex, options],
  );

  const handleKey = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      switch (ev.key) {
        case 'Tab':
          if (activeIdx) {
            setValue(options[activeIdx]);
          }
          setIsOpen(false);
          break;
        case 'Escape':
          setIsOpen(false);
          clearActiveIndex();
          break;
        case 'Enter':
          if (isOpen) {
            // Don't submit form
            ev.preventDefault();
            selectValue();
          }
          break;
        case 'Down':
        case 'ArrowDown':
          // Don't scroll the page
          ev.preventDefault();
          if (activeIdx == null) {
            goToFirst();
          } else {
            gotoNext();
          }
          break;
        case 'Up':
        case 'ArrowUp':
          // Don't scroll the page
          ev.preventDefault();
          goToPrevious();
          break;
        default:
      }
    },
    [activeIdx, clearActiveIndex, goToFirst, goToPrevious, gotoNext, isOpen, options, selectValue],
  );

  const tippyContent = () => (
    <Menu
      className={bem('-menu')}
      id={uid()}
      onMouseLeave={() => {
        if (document.activeElement !== inputRef.current) setIsOpen(false);
      }}
      role="listbox"
    >
      {!!menuHeader && <MenuHeader>{menuHeader}</MenuHeader>}
      {options.map((option, i) => {
        return (
          <MenuItem
            key={`${option}_${i}`}
            aria-selected={activeIdx === i}
            id={`${uid()}_${i}`}
            onClick={() => selectValue(i)}
            role="option"
            tabIndex={-1}
          >
            {option}
          </MenuItem>
        );
      })}
    </Menu>
  );

  return (
    <div className={bem('-wrapper')}>
      <Input
        ref={inputRef}
        {...attrs}
        aria-activedescendant={activeIdx ? `${uid()}_${activeIdx}` : ''}
        aria-autocomplete={filterSuggestions ? 'list' : 'none'}
        aria-controls={uid()}
        aria-expanded={isOpen ? 'true' : 'false'}
        autoComplete="off"
        className={bem('-input')}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={() => {
          if (!deferAutoOpen.current) {
            setIsOpen(true);
          }
          if (filterSuggestions && activeIdx == null) {
            goToFirst();
          }
          deferAutoOpen.current = false;
        }}
        onKeyDown={handleKey}
        role="combobox"
        value={value}
      />
      {!!options.length && (
        <Tippy
          arrow={false}
          className={bem('-tippy')}
          content={tippyContent()}
          interactive
          offset={[0, 4]}
          onClickOutside={() => {
            setIsOpen(false);
          }}
          placement="bottom-start"
          reference={inputRef}
          visible={isOpen}
        />
      )}
    </div>
  );
}

export default ComboBox;
