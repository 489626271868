import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { MyDevelopersSubrouteType } from '@core/enums/metrics';
import useClassy from '@core/hooks/useClassy';
import useMetricsAPI from '@core/hooks/useMetricsAPI';
import useMyDevelopers from '@core/hooks/useMyDevelopers';
import { useMetricsStore, useSuperHubStore } from '@core/store';
import type { KeyInsightsResponse } from '@core/types/metrics';

import MetricsDateRange from '@routes/Dash/Project/Metrics/DateRange';
import MetricsSearch from '@routes/Dash/Project/Metrics/Search';

import Avatar from '@ui/Avatar';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import APIKeyDropdown from '@ui/Metrics/APIKeyDropdown';
import Owlmoji from '@ui/Owlmoji';
import Spinner from '@ui/Spinner';
import Title from '@ui/Title';

import styles from './index.module.scss';

interface Props {
  disableCollapseButton: boolean;
}

const TopBar = ({ disableCollapseButton = false }: Props) => {
  const bem = useClassy(styles, 'TopBar');

  const [
    activeAPIKey,
    type,
    identifier,
    activeSegment,
    rangeQueryParams,
    generateRequestPath,
    isReadyToFetch,
    showDemoData,
  ] = useMetricsStore(s => [
    s.myDevelopers.activeAPIKey,
    s.myDevelopers.type,
    s.myDevelopers.identifier,
    s.myDevelopers.getMatchingSegment(),
    s.myDevelopers.getRangeQueryParams(),
    s.myDevelopers.generateRequestPath,
    s.myDevelopers.getReadyToFetch(),
    s.showDemoData,
  ]);

  const [isMyDevPanelExpanded, updateIsMyDevPanelExpanded] = useSuperHubStore(s => [
    s.isMyDevPanelExpanded,
    s.updateIsMyDevPanelExpanded,
  ]);

  const { buildUrl, navigate } = useMyDevelopers();
  const history = useHistory();

  const isAPIKeyInsightsPage = type === MyDevelopersSubrouteType.Key && !!identifier;
  const isUsersPage = type === MyDevelopersSubrouteType.User && !!identifier;
  const isSegment = type === MyDevelopersSubrouteType.Segment && !!activeSegment;

  const { data, isLoading } = useMetricsAPI<KeyInsightsResponse>(
    generateRequestPath('developers/keyInsights', rangeQueryParams),
    isAPIKeyInsightsPage && isReadyToFetch,
  );

  const { apiKeys = [], company } = data || {};

  const navigateToAPIKey = useCallback(
    (key: string) => {
      const url = buildUrl({ type: MyDevelopersSubrouteType.Key, identifier: key });
      navigate(url);
    },
    [buildUrl, navigate],
  );

  const showNavigateBack = useMemo(() => {
    return type === MyDevelopersSubrouteType.Key || type === MyDevelopersSubrouteType.User;
  }, [type]);

  const navigateToDefaultView = useCallback(() => {
    const url = buildUrl();
    navigate(url);
  }, [buildUrl, navigate]);

  return (
    <div className={bem('&')}>
      <Flex align="center" gap="xs" justify="start">
        {!!showNavigateBack && (
          <Button kind="secondary" onClick={navigateToDefaultView} size="sm" text>
            <Icon name="arrow-left" />
          </Button>
        )}

        {isAPIKeyInsightsPage ? (
          isLoading ? (
            <div className={bem('-insights-loading')}>
              <Spinner size="sm" />
            </div>
          ) : (
            <Flex align="center" gap="sm">
              <APIKeyDropdown
                activeAPIKey={activeAPIKey}
                apiKeys={apiKeys}
                menuHeader={company?.name ? `${company?.name} API Keys` : 'API Keys'}
                onClick={navigateToAPIKey}
                theme="dark"
              />
              <Flex align="center" className={bem('-insights-company')} gap="xs">
                {!!company?.logo && (
                  <Avatar className={bem('-insights-companyLogo')} imgSrc={company?.logo} size="xs" />
                )}
                <Title as={1} className={bem('-title')}>
                  {company?.name || <span>&mdash;</span>}
                </Title>
              </Flex>
            </Flex>
          )
        ) : isUsersPage ? (
          <Flex align="center" gap="xs">
            <Icon color="gray80" name="user" />
            <Title as={1} className={bem('-title')}>
              {identifier}
            </Title>
          </Flex>
        ) : isSegment ? (
          <>
            <Title as={1} className={bem('-title')}>
              API Requests{' '}
            </Title>
            <Flex align="center" className={bem('-title-breadcrumb')} gap="xs">
              <Owlmoji>{activeSegment?.emoji}</Owlmoji>
              <Title as={1} className={bem('-title')}>
                {activeSegment?.title}
              </Title>
            </Flex>
          </>
        ) : showDemoData ? (
          <Flex align="center" gap="sm" justify="center">
            <Button
              circular
              className={bem('-view-demo')}
              kind="primary"
              onClick={() => {
                // We have to remove ?demo=true from URL manually because query params aren't handled
                // nicely with HashRouter
                window.history.replaceState(null, '', `${window.location.pathname}${window.location.hash}`);
                history.push('/my-developers/setup');
              }}
              size="sm"
              style={{ backgroundColor: 'var(--green10)' }}
            >
              <Icon name="arrow-left" /> Exit Demo
            </Button>

            <Title as={1} className={bem('-title')}>
              API Requests
            </Title>
          </Flex>
        ) : (
          <Flex align="center" gap="sm" justify="center">
            <Title as={1} className={bem('-title')}>
              API Requests
            </Title>
          </Flex>
        )}
      </Flex>
      <div className={bem('-search')}>
        {/* Search is hidden on API Key insights */}
        {!isAPIKeyInsightsPage && <MetricsSearch isMyDevelopers isSuperhub />}
      </div>
      <Flex className={bem('-actions')} gap="sm">
        <MetricsDateRange isDropdown isMyDevelopers />
        <Button disabled={disableCollapseButton} ghost kind="secondary" onClick={updateIsMyDevPanelExpanded} size="sm">
          <Icon name={isMyDevPanelExpanded ? 'minimize-2' : 'maximize-2'} />
        </Button>
      </Flex>
    </div>
  );
};

export default TopBar;
