import type { ExternalRepositoriesListType } from '@readme/api/src/routes/gitSync/types';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';

import { ProjectContext, type ProjectContextValue } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useReadmeApiNext, fetcher } from '@core/hooks/useReadmeApi';

import { useSuperHubActionSlotContext } from '@routes/SuperHub/Layout/Context';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Icon from '@ui/Icon';
import Radio from '@ui/Radio';
import RadioGroup from '@ui/RadioGroup';
import Select from '@ui/Select';
import SmartLink from '@ui/SmartLink';
import Spinner from '@ui/Spinner';

import { Fieldset, FormRow } from '../shared';

import classes from './index.module.scss';

function providerLabel(provider: string) {
  switch (provider) {
    case 'github':
      return 'GitHub';
    case 'gitlab':
      return 'GitLab';
    default:
      return provider;
  }
}

// eslint-disable-next-line consistent-return
function manageConnectionLink(provider: string, selectedOrg: ExternalRepositoriesListType['owner'] | undefined) {
  // `selectedOrg` may come through as undefined on the first
  // render when React is still fetching the data. In that case,
  // we should return an empty string to avoid a broken link.
  // The link of course won't go anywhere in that case.
  if (!selectedOrg) return '';

  // eslint-disable-next-line default-case
  switch (provider) {
    case 'github':
      // eslint-disable-next-line default-case
      switch (selectedOrg.type) {
        case 'User':
          return `https://github.com/settings/installations/${selectedOrg.connectionId}`;
        case 'Organization':
          return `https://github.com/organizations/${selectedOrg.login}/settings/installations/${selectedOrg.connectionId}`;
      }
    // eslint-disable-next-line no-fallthrough
    case 'gitlab':
      // Not yet implemented
      return '';
  }
}

export default function GitConnectionChooseRepo({ onConnectionSave }: { onConnectionSave: () => void }) {
  const bem = useClassy(classes, 'GitConnectionChooseRepo');
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { subdomain } = project;
  const actionSlot = useSuperHubActionSlotContext();
  const provider = project.git.sync.connectedRepository?.provider || 'github';
  const [isSyncing, setIsSyncing] = useState(false);
  const [connectionError, setConnectionError] = useState<{ detail?: string; title: string } | null>(null);
  const history = useHistory();

  const {
    data: { data = [] } = {},
    isLoading: isRepositoryDataLoading,
    error: loadRepositoriesError,
  } = useReadmeApiNext<{
    data: ExternalRepositoriesListType[];
  }>(`/git_sync/repositories?provider=${provider}`, {
    swr: {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    },
  });

  // If the repositories list api 403s, it likely means the user
  // has uninstalled the github app, or there are no connections
  // available to us. Forcing a refresh here takes them back to the
  // onboarding screen.
  if (loadRepositoriesError?.status === 403) {
    history.go(0);
  }

  const [selectedRepo, setSelectedRepo] = useState<string | null>(null);
  const [selectedOrg, setSelectedOrg] = useState(data.length ? data[0].owner : undefined);

  const repositories = useMemo(() => {
    const orgIndex = data.length && selectedOrg ? data.findIndex(({ owner }) => owner.login === selectedOrg.login) : 0;
    return data.length && data[orgIndex] ? data[orgIndex].repositories : [];
  }, [data, selectedOrg]);

  useEffect(() => {
    if (data.length && !selectedOrg) {
      setSelectedOrg(data[0].owner);
    }
  }, [data, selectedOrg]);

  const handleOrgSelection = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const orgIndex = data.length ? data.findIndex(({ owner }) => owner.login === e.currentTarget.value) : 0;
      const org = data[orgIndex].owner || undefined;
      setSelectedOrg(org);
    },
    [data],
  );

  const handleRepoSelection = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRepo(e.currentTarget.value);
  }, []);

  const handleSync = useCallback(async () => {
    const connectRepository = repositories.find(repo => repo.id === selectedRepo);
    setIsSyncing(true);
    setConnectionError(null);

    try {
      await fetcher(`/${subdomain}/api-next/v2/git_sync/repositories/connected`, {
        method: 'POST',
        body: JSON.stringify({
          owner: selectedOrg,
          repository: connectRepository,
        }),
      });

      project.mutate({
        git: {
          sync: {
            connectedRepository: connectRepository,
          },
        },
      });
      onConnectionSave();
    } catch (error) {
      setConnectionError({
        title: error?.info?.title || error.message,
        detail: error?.info?.detail,
      });
    } finally {
      setIsSyncing(false);
    }
  }, [selectedRepo, project, repositories, subdomain, selectedOrg, onConnectionSave]);

  const organizationSelectOptions = useMemo(
    () =>
      data.map(({ owner }) => {
        return {
          label: owner.login,
          value: owner.login,
        };
      }),
    [data],
  );

  const isReady = !isRepositoryDataLoading;

  const ErrorMessage = connectionError ? (
    <>
      <p>{connectionError.title}</p>
      <p>{connectionError.detail}</p>
    </>
  ) : null;

  return (
    <Fieldset legend={<Flex justify="start">{providerLabel(provider)} Connection</Flex>}>
      {!isReady ? (
        <Spinner className={bem('-spinner')} size="md" />
      ) : (
        <FormRow columns={2}>
          <FormGroup
            description={
              <SmartLink href={`/${subdomain}/api-next/v2/git_sync/github/init`}>Add New Organization</SmartLink>
            }
            label="Choose Organization"
          >
            <Select
              defaultValue={selectedOrg?.login}
              onChange={handleOrgSelection}
              options={organizationSelectOptions}
              size="sm"
            />
          </FormGroup>
          <FormGroup
            description={
              <SmartLink href={manageConnectionLink(provider, selectedOrg)} rel="noreferrer" target="_blank">
                <Flex align="center" gap="xs">
                  <Icon name="github-filled" />
                  Manage Connection
                  <Icon name="arrow-up-right" />
                </Flex>
              </SmartLink>
            }
            errorMessage={ErrorMessage}
            label="Choose Repository"
          >
            <RadioGroup fullWidth>
              {repositories.map(repo => (
                <Radio
                  key={repo.id}
                  checked={selectedRepo === repo.id}
                  label={repo.name}
                  name="repository"
                  onChange={handleRepoSelection}
                  value={repo.id}
                />
              ))}
            </RadioGroup>
            <Flex align="center" className={classes['GitConnection-info']} gap="xs" justify="start">
              <Icon name="info" size="sm" />
              Must be an empty repository to sync successfully.
            </Flex>
          </FormGroup>
        </FormRow>
      )}

      {!!actionSlot &&
        createPortal(
          <Flex align="center" gap={15} justify="end">
            <Button disabled={!selectedRepo} loading={isSyncing} onClick={handleSync}>
              Sync Repository
            </Button>
          </Flex>,
          actionSlot,
        )}
    </Fieldset>
  );
}
