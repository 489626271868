import React from 'react';

import { Layout } from '@routes/Dash/CustomBlocks/Management';
import { CustomBlockMgmtContextProvider } from '@routes/Dash/CustomBlocks/Management/Context';

import { Page } from '../../shared';

function CustomComponents() {
  return (
    <Page renderActionSlot={false}>
      <CustomBlockMgmtContextProvider type="component">
        <Layout />
      </CustomBlockMgmtContextProvider>
    </Page>
  );
}

export default CustomComponents;
