import React from 'react';

import useClassy from '@core/hooks/useClassy';
import useEnvInfo from '@core/hooks/useEnvInfo';

import CopyCode from '@routes/MyDevelopers/Setup/PersonalizedDocs/CopyCode';

import Flex from '@ui/Flex';

import { awsApiGatewayPolicyText } from './AWSConstants';
import styles from './style.module.scss';

const README_DEV_AWS_ACCOUNT_ID = '590183764954';
const README_AWS_ACCOUNT_ID = '541752793046';

interface Props {
  externalId: string;
}

function AWSCrossAccountInfo({ externalId }: Props) {
  const bem = useClassy(styles, 'SetupAWSPersonalizationForm');
  const { isDev } = useEnvInfo();

  const readmeAwsAccountId = isDev ? README_DEV_AWS_ACCOUNT_ID : README_AWS_ACCOUNT_ID;

  return (
    <Flex align="stretch" gap="sm" justify="start" layout="col">
      <ol className={bem('-cross-acount-list')}>
        <li>
          <p>
            Sign in to the{' '}
            <a href="https://console.aws.amazon.com/iam/home#/roles" rel="nofollow">
              AWS IAM console
            </a>{' '}
            and select <strong>Create role</strong>.
          </p>
        </li>
        <li>
          <p>
            Under <strong>Select trusted entity</strong>, select <strong>AWS account</strong>.
          </p>
        </li>
        <li>
          <p>
            Under <strong>An AWS account</strong>, select <strong>Another AWS account</strong> and enter ReadMe&apos;s{' '}
            <strong>AWS Account ID</strong>:
          </p>
          <CopyCode>{readmeAwsAccountId}</CopyCode>
        </li>
        <li>
          <p>
            Under <strong>Options</strong>, select the <strong>Require external ID</strong> checkbox and enter the{' '}
            <strong>External ID</strong>:
          </p>
          <CopyCode>{externalId}</CopyCode>
        </li>
        <li>
          <p>
            Select <strong>Next: Permissions</strong>.
          </p>
        </li>
        <li>
          <p>
            Select an existing IAM policy or select <strong>Create policy</strong>. If you are creating a policy, use
            the following JSON code:
          </p>

          <CopyCode variant="multiline">{awsApiGatewayPolicyText}</CopyCode>

          <p>This policy will enable reading usage plans and reading and writing API keys, usage plans, and tags</p>
          <ul style={{ marginBottom: 'var(--sm)' }}>
            <li>
              <code>apigateway:GET</code> - (Required) Enables viewing API Gateway usage plans and API keys.
            </li>
            <li>
              <code>apigateway:PUT</code> - (Required) Enables creating API Gateway API keys.
            </li>
            <li>
              <code>apigateway:POST</code> - (Required) Enables creating API Gateway API keys.
            </li>
            <li>
              <code>apigateway:DELETE</code> - (Required) Enables deleting API Gateway API keys.
            </li>
          </ul>
        </li>
        <li>
          <p>
            Select <strong>Next: Tags</strong>.
          </p>
        </li>
        <li>
          <p>
            Select <strong>Next: Review</strong>.
          </p>
        </li>
        <li>
          <p>
            Add a <strong>Role name</strong> and <strong>Role description</strong>, then select{' '}
            <strong>Create role</strong>.
          </p>
        </li>
        <li>
          <p>Navigate to the newly created role and paste the required information below.</p>
        </li>
      </ol>
    </Flex>
  );
}

export default AWSCrossAccountInfo;
