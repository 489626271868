import type { ConfigureNavItemProps } from './ConfigureNavItem';

import React from 'react';
import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { PLAN_UPGRADE_URL } from '@core/constants/urls';
import useClassy from '@core/hooks/useClassy';
import useProjectPlan from '@core/hooks/useProjectPlan';
import { useProjectStore } from '@core/store';

import useIsWebhookSetupComplete from '@routes/MyDevelopers/Setup/PersonalizedDocs/useIsWebhookSetupComplete';
import BasePanel from '@routes/SuperHub/Layout/BasePanel';
import { SuperHubHashRoutePaths, type SuperHubHashRouteParams } from '@routes/SuperHub/types';

import ProjectSettingsForm from '../Form/Project';
import CustomComponents from '../Form/Project/CustomBlocks/Components';
import ReusableContent from '../Form/Project/CustomBlocks/ReusableContent';
import CustomLogin from '../Form/Project/CustomLogin';
import GitConnection from '../Form/Project/GitConnection';
import Glossary from '../Form/Project/Glossary';
import HealthCheck from '../Form/Project/HealthCheck';
import Integrations from '../Form/Project/Integrations';
import PersonalizedDocs from '../Form/Project/PersonalizedDocs';
import PersonalizedDocsSetup from '../Form/Project/PersonalizedDocs/Setup';
import ProjectSettings from '../Form/Project/ProjectSettings';
import { SidebarNav, SidebarNavSection } from '../Form/Project/shared';

import ConfigureNavItem from './ConfigureNavItem';
import styles from './index.module.scss';

const configureNavData = [
  {
    section: 'Project',
    items: [
      {
        icon: 'book-open',
        label: 'Project Settings',
        to: '/configure/project-settings',
      },
      {
        icon: 'pie-chart',
        label: 'Documentation Metrics',
        href: '/dash?to=metrics/page-views',
      },
      {
        icon: 'alert-triangle',
        label: 'Error Pages',
        href: '/dash?to=errors',
        planFeature: 'errors',
      },
      {
        icon: 'radio',
        label: 'Health Check',
        to: '/configure/health-check',
        planFeature: 'healthCheck',
      },
    ],
  },
  {
    section: 'Access',
    items: [
      {
        icon: 'key',
        label: 'API Keys',
        href: '/dash?to=api-key',
      },
      {
        icon: 'teammates',
        label: 'Manage Team',
        href: '/dash?to=users',
      },
      {
        icon: 'unlock',
        label: 'Custom Login',
        to: '/configure/custom-login',
      },
      {
        icon: 'lock',
        label: 'SAML Authentication',
        href: '/dash?to=saml',
      },
    ],
  },
  {
    section: 'Admin',
    items: [
      {
        icon: 'credit-card',
        label: 'Manage Plan',
        href: PLAN_UPGRADE_URL,
      },
      {
        icon: 'globe',
        label: 'Custom Domain',
        href: '/dash?to=domains',
        planFeature: 'custom_domain',
      },
      {
        icon: 'minimize-2',
        label: 'Integrations',
        to: '/configure/integrations',
      },
      {
        icon: 'labs',
        label: 'Labs',
        href: '/dash?to=labs',
      },
      {
        icon: 'git-branch',
        isBeta: true,
        label: 'Git Connection',
        to: '/configure/git-connection',
      },
    ],
  },
] satisfies { items: ConfigureNavItemProps[]; section: string }[];

function Configure() {
  const bem = useClassy(styles, 'Configure');

  const { pathname } = useLocation();
  const { planFeatures } = useProjectPlan();
  const isCustomComponentsEnabled = useProjectStore(
    s => s.data.features.custom_components === 'enabled' && s.data.features.mdx === 'enabled',
  );

  const isSetupComplete = useIsWebhookSetupComplete();
  const match = matchPath<SuperHubHashRouteParams>(pathname, Object.values(SuperHubHashRoutePaths));
  const action = match?.params.action;

  const contentNavData = [
    {
      icon: 'recycle',
      label: 'Reusable Content',
      to: '/content/reusable-content',
      planFeature: 'reusableContent',
    },
    {
      icon: 'bookmark',
      label: 'Glossary',
      to: '/content/glossary',
      planFeature: 'glossary',
    },
    {
      icon: 'key',
      label: 'Personalized Docs',
      to: isSetupComplete ? '/content/personalized-docs' : '/content/personalized-docs/setup',
    },
    {
      icon: 'home',
      label: 'Landing Page',
      href: '/dash?to=landing',
      planFeature: 'landing_page',
    },
  ] satisfies ConfigureNavItemProps[];

  // Conditionally add Custom Components as second nav item if enabled
  if (isCustomComponentsEnabled) {
    contentNavData.splice(1, 0, {
      icon: 'box',
      label: 'Custom Components',
      to: '/content/custom-components',
    });
  }

  return (
    <BasePanel label="Project settings controls">
      <div className={bem('-layout')} data-testid="configure-panel">
        <SidebarNav className={bem('-sidebar')}>
          {action === 'configure' && (
            <>
              {configureNavData.map(({ section, items }) => (
                <SidebarNavSection key={section} label={section}>
                  {items.map((props: ConfigureNavItemProps) => (
                    <ConfigureNavItem key={`configure-${section}-${props.label}`} {...props} />
                  ))}
                </SidebarNavSection>
              ))}
            </>
          )}
          {action === 'content' && (
            <SidebarNavSection>
              {contentNavData.map((props: ConfigureNavItemProps) => (
                <ConfigureNavItem key={`content-${props.label}`} {...props} />
              ))}
            </SidebarNavSection>
          )}
        </SidebarNav>

        {/* Routes to render sub-forms, grouped by sections in alphabetical order for readability */}
        <Switch>
          {/* Configure routes */}
          <Route path="/:action(configure)/:section(custom-login)">
            <ProjectSettingsForm>
              <CustomLogin />
            </ProjectSettingsForm>
          </Route>
          <Route path="/configure/git-connection">
            <GitConnection />
          </Route>
          <Route path="/:action(configure)/:section(health-check)">
            <ProjectSettingsForm formGate={planFeatures.healthCheck ? 'none' : 'plan'}>
              <HealthCheck />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(configure)/:section(integrations)">
            <ProjectSettingsForm>
              <Integrations />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(configure)/:section(project-settings)">
            <ProjectSettingsForm>
              <ProjectSettings />
            </ProjectSettingsForm>
          </Route>
          <Route exact path="/:action(configure)">
            <Redirect to="/configure/project-settings" />
          </Route>

          {/* Content routes */}
          <Route path="/:action(content)/:section(reusable-content)">
            <ReusableContent />
          </Route>
          {!!isCustomComponentsEnabled && (
            <Route path="/:action(content)/:section(custom-components)">
              <CustomComponents />
            </Route>
          )}
          <Route path="/:action(content)/:section(glossary)">
            <ProjectSettingsForm>
              <Glossary />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(content)/:section(personalized-docs)/:type(setup)">
            <ProjectSettingsForm>
              <PersonalizedDocsSetup />
            </ProjectSettingsForm>
          </Route>
          <Route path="/:action(content)/:section(personalized-docs)">
            <ProjectSettingsForm>
              <PersonalizedDocs />
            </ProjectSettingsForm>
          </Route>
          <Route exact path="/:action(content)">
            <Redirect to="/content/reusable-content" />
          </Route>
        </Switch>
      </div>
    </BasePanel>
  );
}

export default Configure;
